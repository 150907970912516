<template>
    <div class="main">
        <img src="../assets/img/minerva_logo.png" class="hs">
        <p class="pm p1">{{$t('address')}}：5-563 HURRICANE DR CALGARY AB T3Z 3S8 (CA)</p>
        <p class="pm p1" style="text-indent: 5em">36/234 THIAM RUAM MIT ROAD.,KHWAENG HUAI KHWANG, KHET HUAI KHWANG, BANGKOK (THA)</p>
        <p class="pm p2">{{$t('email')}}：<a href="mailto:info@minervaic.com">info@minervaic.com</a></p>
    </div>
</template>

<script>

    export default {
        name: 'Contact',
    }
</script>

<style scoped>
    p {
        color: #2c3e50;
        font-weight: bold;
    }

    div .main {
        text-align: left;
        float: left;
    }

    .hs {
        height: 4em;
        margin-top: 5em;
        margin-bottom: 2em;
    }

    @media (max-width: 700px) {
        .hs {
            height: 3em;
            margin-top: 5em;
        }
        p {
            font-size: 1em !important;
        }
    }


    @media (min-width: 1200px) {
        div .main {
            margin-top: 30%;
            margin-left: 6em;
        }

        p {
            font-size: 2em;
        }
    }

    @media (max-height: 1199px) {
        div .main {
            margin-top: 20%;
            margin-left: 4em;
        }

        p {
            font-size: 1.5em;
        }
    }


</style>
