<template>
    <div id="app">
        <el-container>
            <el-header id="header">
                <a style="float: left" @click="$router.push('/')"><img :src="this.$route.path.startsWith('/prod')?logo_white:logo" style="float: left; margin: 0.6em 0 0 1em; height: 2.6em"></a>
                <div class="header_menu_div">
                    <el-menu  mode="horizontal" :default-active="$route.path"
                              active-text-color="#6E92D1" :text-color="menuTextColor()" @select="changeBg" :router="true"
                              style="margin-right: 2em">
                        <el-menu-item index="/">{{$t('home')}}</el-menu-item>
                        <!--                        <el-menu-item index="/intro">{{$t('intro')}}</el-menu-item>-->
                        <el-submenu index="prod" popper-class="el-menu-popup">
                            <template slot="title"><span class="el-submenu">{{$t('product')}}</span></template>
                            <el-menu-item index="/prod/btc" class="dropdown-menu-item">{{$t('btc')}}</el-menu-item>
                            <el-menu-item index="/prod/ethereum" class="dropdown-menu-item">{{$t('eth')}}</el-menu-item>
                            <el-menu-item index="/prod/ltc" class="dropdown-menu-item">{{$t('ltc')}}</el-menu-item>
                        </el-submenu>
                        <el-menu-item index="/ai">{{$t('aiProd')}}</el-menu-item>
                        <el-menu-item index="/contact">{{$t('contact')}}</el-menu-item>
                    </el-menu>
                    <!--                    <v-icon name="web" class="filter-green"  style="vertical-align: bottom; margin-bottom: 0.1em" scale="1.4"></v-icon>-->
                    <!--                    <img src="./assets/img/globe.png" style="height: 1.6em; margin-right: 0.3em; margin-bottom: 0.9em">-->
                    <!--                    <el-select v-model="lang" @change="changeLang" size="medium" style="width: 80px; float: right; margin: 1em 2em 0 0" >-->
                    <!--&lt;!&ndash;                        <i :class="`flag-icon ${lang == 'cn'?'flag-icon-cn':'flag-icon-us'}`" slot="prefix" style="margin-top: 50%;"></i>&ndash;&gt;-->
                    <!--                        <el-option value="cn" label="中文">-->
                    <!--                            <span class="flag-icon flag-icon-cn"></span> 简体中文-->
                    <!--                        </el-option>-->
                    <!--                        <el-option value="en" label="EN">-->
                    <!--                            <span class="flag-icon flag-icon-us"></span> English-->
                    <!--                        </el-option>-->
                    <!--                    </el-select>-->
                </div>
            </el-header>
            <el-main>
                <div>
                    <div class="menu_div">
                        <el-menu  mode="horizontal" :default-active="$route.path"
                                  active-text-color="#6E92D1" :text-color="menuTextColor()" @select="changeBg" :router="true"
                                  style="margin-right: 2em; z-index: 1000">
                            <el-menu-item index="/">{{$t('home')}}</el-menu-item>
                            <!--                        <el-menu-item index="/intro">{{$t('intro')}}</el-menu-item>-->
                            <el-submenu index="prod" popper-class="el-menu-popup">
                                <template slot="title"><span class="el-submenu">{{$t('product')}}</span></template>
                                <el-menu-item index="/prod/btc" class="dropdown-menu-item">{{$t('btc')}}</el-menu-item>
                                <el-menu-item index="/prod/ethereum" class="dropdown-menu-item">{{$t('eth')}}</el-menu-item>
                                <el-menu-item index="/prod/ltc" class="dropdown-menu-item">{{$t('ltc')}}</el-menu-item>
                            </el-submenu>
                            <el-menu-item index="/ai" style="padding-right: 20px">AI</el-menu-item>
                            <el-menu-item index="/contact">{{$t('contact')}}</el-menu-item>
                        </el-menu>
                    </div>
                </div>
                <router-view/>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import AOS from 'aos';
    AOS.init();

    export default {
        data() {
            return {
                bg_home: require('./assets/img/bg_home.png'),
                bg_intro: require('./assets/img/bg_intro.png'),
                bg_prod: require('./assets/img/bg_prod.png'),
                bg_ai: require('./assets/img/bg_contact.png'),
                bg_contact: require('./assets/img/bg_contact.png'),
                logo: require('./assets/img/minerva_logo.png'),
                logo_white: require('./assets/img/minerva_logo_white.png'),
                lang: localStorage.lang || (navigator.language.startsWith('zh')? 'cn': 'en')
            }
        },
        methods: {
            changeBg(path) {
                console.log(path)
                let bg = `bg_${path == '/' ? 'home' : path.split('/')[1]}`;
                document.body.style.backgroundImage = `url(${this[bg]})`;
            },
            changeLang(value) {
                this.$i18n.locale = value;
                localStorage.lang = value;
            },
            menuTextColor(){
                return this.$route.path.startsWith('/prod')?'#fff': '#666';
            }
        },
        mounted() {
            this.changeBg(this.$route.path)
        },
        watch: {
            $route(to, from) {
                this.changeBg(to.path);
            }
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    html {
        height: 100%;
    }

    body {
        /*background-image: url("./assets/img/bg_home.png");*/
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
    }

    .el-header {
        /*background-image: url("./assets/img/bg_header.png");*/
        /*background-repeat: repeat-x;*/
        background-color: rgba(13, 45, 80, 0.3);
        padding: 0;
    }

    .el-menu {
        background-color: rgba(255, 255, 255, 0) !important;
    }
    .el-main {
        /*padding-top: 20px;*/
        padding: 0 !important;
    }
    .menu_div, .header_menu_div {
        margin-right: 1em;
        float: right;
        z-index: 100;
    }

    @media (max-width: 600px) {
        .header_menu_div {
            display: none;
        }
        .el-menu {
            margin-right: 0 !important;
        }
        .el-main {
            padding: 0 !important;
        }
    }

    @media (min-width: 601px) {
        .menu_div {
            display: none;
            margin-right: 0 !important;
            z-index: 100;
        }
    }

    @media (max-width: 300px) {
        .el-menu-item {
            font-size: 1em !important;
        }
    }
    @media (min-width: 301px) {
        .el-menu-item {
            font-size: 1.2em !important;
        }
    }
    .el-menu-item {
        font-family: '微软雅黑' !important;
        font-weight: bold;
        background-color: rgba(255, 255, 255, 0) !important;
    }

    .dropdown-menu-item {
        font-weight: normal;
        font-size: 1.1em !important;
        color: #666 !important;
    }

    .el-menu-item:hover {
        color: #6E92D1 !important;
    }

    .el-submenu {
        font-size: 1.2em !important;
        font-family: '微软雅黑' !important;
        font-weight: bold;

    }

    .el-submenu:hover {
        color: #6E92D1 !important;
    }

    .el-menu-popup {
        background-color: #fff !important;
        color: #666 !important;
    }
</style>

<style scoped>
    >>>.el-input__inner {
        border: none;
        border-bottom: 2px solid #6E92D1;
    }
    @media (max-width: 768px) {
        .el-menu-item {
            padding: 0
        }
    }

</style>
