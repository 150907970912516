<template>
    <div id="prod">
        <el-row>
            <el-col :xl="24">
                <img src="../assets/img/miner.png" class="miner">
                <div class="desc">
                    <div>
                        <transition name="slide-fade">
                            <table v-if ="showTable[0]">
                                <tr>
                                    <td style="opacity: 80%" class="font_sm">Product</td>
                                    <td rowspan="3" class="font_xl font_bold">MV7</td>
                                </tr>
                                <tr>
                                    <td class="font_md font_bold">{{$t('prod0')}}</td>
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td colspan="2" class="font_sm">{{$t('prod1')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[1]">
                                <tr>
                                    <td colspan="2" class="font_md font_bold">Performance</td>
                                </tr>
                                <tr>
                                    <td class="font_sm">{{$t('prod2')}}</td>
                                </tr>
                                <tr>
                                    <td rowspan="2" style="width: 3.75em" class="font_lg">>120T</td>
                                    <td class="font_sm">{{$t('prod3')}}，</td>
                                </tr>
                                <tr>
                                    <td class="font_sm">{{$t('prod4')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                    <div>
                        <transition name="slide-fade" style="overflow: hidden">
                            <table v-if="showTable[2]">
                                <tr>
                                    <td class="font_sm">{{$t('prod5')}}</td>
                                </tr>
                                <tr>
                                    <td rowspan="2" class="font_lg" style="width: 4.5em"><30J/Th</td>
                                    <td class="font_sm">{{$t('prod6')}}，</td>
                                </tr>
                                <tr>
                                    <td class="font_sm">{{$t('prod7')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    export default {
        name: 'Prod',
        data() {
            return {
                showTable: []
            }
        },
        mounted() {
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    this.showTable.push(true)
                }, 888 * (i + 1))
            }
        }
    }
</script>

<style>
    .miner {
        margin: 0;
        position: fixed; bottom: 0; left: 0;
        height: 25em;
        z-index: -1;
    }

    div .desc {
        color: #fff;
        display: inline-block;
        width: 40em;
        float: right;
        text-align: left;
        margin-top: 5em;
    }

    .font_sm {
        font-size: 1.5em;
    }
    .font_md {
        font-size: 2.5em;
    }
    .font_xl {
        font-size: 6em;
    }
    .font_lg {
        font-size: 3.5em;
    }
    .font_bold {
        font-weight: bold;
    }

    @media (max-width: 700px) {
        .miner {
            height: 25%;
            bottom: 1em
        }
        td {
            padding: 0 15px;
        }
        div .desc {
            margin: 2em 0 0 6em;
            width: 100%;
        }
        .font_sm {
            font-size: 1em;
        }
        .font_md {
            font-size: 1.5em;
        }
        .font_xl {
            font-size: 3em;
        }
        .font_lg {
            font-size: 1.75em;
        }
    }
    @media (min-width: 610px) and (max-width: 1200px) {
        .font_sm {
            font-size: 1em;
        }
        .font_md {
            font-size: 1.5em;
        }
        .font_xl {
            font-size: 3em;
        }
        .font_lg {
            font-size: 1.75em;
        }
        div .desc {
            width: 40em;
            margin-top: 5em;
        }
        .miner { height: 35%; }
    }

    @media (min-width: 1200px) and (min-height: 1200px) {
        .miner {
            height: 45em;
        }
        div .desc {
            margin-top: 5em;
            margin-right: 4em;
            width: 50em;
        }
    }
    @media (max-height: 600px) {
        div .desc {
            margin-top: 1em;
            float: right;
            max-width: 55%;
        }
    }
    .desc div {
        overflow: hidden;
    }
</style>

<style scoped>
    .aside {
        background-image: url("../assets/img/bg_intro_side.png");
        background-size: cover;
        padding: 2em 1em;
        height: 100%;
    }

    @media (max-width: 700px) {
        .miner {
            bottom: 0 !important;
        }
    }


    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>

<style>

</style>
