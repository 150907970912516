<template>
    <div>
        <div style="height: 15em">
            <el-card v-for="p in players" :key="p.id" style="display: inline-block; margin: 0.5em">
                <img :src="`assets/images/draw/${p.id}.jpg`" style="width: 6em">
                <div style="padding: 14px;">
                    <h4>{{p.nickname}}</h4>
                </div>
            </el-card>
        </div>
        <div>
            <el-row>
                <el-col :span="8">
                    <el-card style="display: inline-block; width: 28em; height:70em; float: right">
                        <div slot="header">
                            <h1>GROUP A</h1>
                        </div>
                        <el-card v-for="pa in group0" :key="pa.id" style="margin: 0.5em auto; width: 11em; height: 11em;">
                            <img :src="`assets/images/draw/${pa.id}.jpg`" style="width: 6em">
                            <div style="padding: 14px;">
                                <h4>{{pa.nickname}}</h4>
                            </div>
                        </el-card>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-button :disabled="disabled" style="width: 40em; height: 10em; margin: 40% auto" type="primary" round><h1 style="color: white" @click="draw">抽签</h1></el-button>
                </el-col>
                <el-col :span="8">
                    <el-card style="display: inline-block; width: 28em; height:70em; float: left">
                        <div slot="header">
                            <H1>GROUP B</H1>
                        </div>
                        <el-card v-for="pb in group1" :key="pb.id" style="margin: 0.5em auto; width: 11em; height: 11em">
                            <img :src="`assets/images/draw/${pb.id}.jpg`" style="width: 6em">
                            <div style="padding: 14px;">
                                <h4>{{pb.nickname}}</h4>
                            </div>
                        </el-card>
                    </el-card>
                </el-col>
            </el-row>

        </div>
    </div>
</template>

<script>

    export default {
        name: 'Contact',
        data(){
            return {
                players:[
                    {id:1, nickname: "老孙"},
                    {id:2, nickname: "西瓜"},
                    {id:3, nickname: "欢"},
                    {id:4, nickname: "偷米"},
                    {id:5, nickname: "女王"},
                    {id:6, nickname: "老张"},
                    {id:7, nickname: "黄总"},
                    {id:8, nickname: "欧米"},
                    {id:9, nickname: "江震"},
                ],
                group0: [],
                group1: [],
                disabled: false,
                g: false,
            }
        },
        methods: {
            draw(){
                if(this.players.length){
                    this.disabled = true;
                    let id = Math.floor(Math.random() * this.players.length);
                    // this[`group${1-this.players.length%2}`].push(this.players.splice(id, 1)[0]);
                    this[`group${this.g * 1}`].push(this.players.splice(id, 1)[0]);
                    this.g = !this.g;
                    setTimeout(this.draw, 1000);
                } else {
                    this.disabled = false;
                }
            },
        }
    }
</script>

<style scoped>


</style>
