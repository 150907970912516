<template>
    <div class="home">
        <el-row>
            <el-col style="text-align: right; " :xl="24">
                <img src="../assets/img/ether1.png" class="miner">
                <div class="desc" style="float: left; text-align: left;">
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[0]">
                                <tr>
                                    <td style="opacity: 80%" class="font_sm">Product</td>
                                    <td rowspan="3" class="font_xl font_bold">E15</td>
                                </tr>
                                <tr>
                                    <td class="font_md font_bold">{{$t('prod0')}}</td>
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td colspan="2" class="font_sm">{{$t('eth1')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[1]">
                                <tr>
                                    <td class="font_md font_bold">Performance</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="font_sm">{{$t('prod2')}}</td>
                                </tr>
                                <tr>
                                    <td rowspan="2" style="width: 3.75em" class="font_lg">>20M/C</td>
                                    <td class="font_sm">{{$t('eth2')}}，</td>
                                </tr>
                                <tr>
                                    <td class="font_sm">{{$t('eth3')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                    <div>
                        <transition name="slide-fade">
                            <table v-if="showTable[2]">
                                <tr>
                                    <td colspan="2" class="font_sm">{{$t('prod5')}}</td>
                                </tr>
                                <tr>
                                    <td rowspan="2" style="width: 4.5em; padding-right: 0.5em" class="font_lg"><5J/Mh</td>
                                    <td class="font_sm">{{$t('eth4')}}，</td>
                                </tr>
                                <tr>
                                    <td class="font_sm">{{$t('eth5')}}</td>
                                </tr>
                            </table>
                        </transition>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    export default {
        name: 'Ethereum',
        data() {
            return {
                showTable: []
            }
        },
        mounted() {
            for (let i = 0; i < 3; i++) {
                setTimeout(() => {
                    this.showTable.push(true)
                }, 888 * (i + 1))
            }
        }
    }
</script>

<style scoped>
    .aside {
        background-image: url("../assets/img/bg_intro_side.png");
        background-size: cover;
        padding: 2em 1em;
        height: 100%;
    }
    .miner {
        position: fixed;
        right: 5em;
        left: auto;
        bottom: 1em;
    }
    .desc {
        margin-left: 5em;
    }

    @media (max-width: 700px) {
        .miner {
            height: 25%;
            left: auto;
            right: 2em;
        }
        td {
            padding: 0 15px;
        }
        div .desc {
            margin-left: 0;
        }

    }
    @media (min-width: 610px) and (max-width: 1200px) {
        .miner {
            height: 35%;
            left: auto;
            right: 2em;
        }
        .desc {
            margin-left: 2em;
            max-width: 65%;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>

<style>

</style>
